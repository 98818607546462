const config = {
  meta: {
    title: 'EOB Clubs',
    fullTitle: 'Enemy of Boredom Clubs',
    titleTemplate: '%s | EOB Clubs',
    twitterHandle: '@eobclubs',
    locale: 'en',
    description:
      'EOB Clubs: Video Games Design and Virtual Reality Holiday Clubs',
    url: 'https://www.irlletchworth.com',
    themeColor: '#E72869'
  },
  social: {
    facebook: 'https://www.facebook.com/EnemyofBoredom/',
    instagram: 'https://www.instagram.com/eobacademy/'
  },
  location: {
    lat: '51.979038',
    lng: '-0.22669'
  },
  eventTypes: [
    {
      type: 'minecraft',
      name: 'Minecraft',
      icon: 'minecraft'
    },
    {
      type: 'holidayClubs',
      name: 'Holiday Clubs',
      icon: 'club'
    },
    {
      type: 'videoGamesDesign',
      name: 'Video Games Design',
      icon: 'video-game'
    }
    // {
    //   type: 'industryTalks',
    //   name: 'Industry Talks',
    //   icon: 'mic'
    // },
    // {
    //   type: 'cosplay',
    //   name: 'Cosplay',
    //   icon: 'cosplay'
    // }
  ],
  bookingStates: {
    booked: 'booked',
    canceled: 'canceled',
    pending: 'pending',
    failed: 'failed',
    refunded: 'refunded',
    ended: 'ended'
  },
  protectedRoutes: [`/booking`, `/dashboard`, '/admin'],
  bookingCities: ['Online', 'Letchworth', 'Bracknell'],
  bookingTypes: [
    // {
    //   type: 'volunteerToGame',
    //   name: 'Volunteer To Game',
    //   icon: 'hand'
    // },
    // {
    //   type: 'familyGaming',
    //   name: 'Family Gaming',
    //   icon: 'family'
    // },
    // {
    //   type: 'party',
    //   name: 'Party Booking',
    //   icon: 'celebration'
    // }
  ],
  navLinks: {
    mainNav: [
      {
        label: 'Events',
        links: [
          // {
          //   label: 'Volunteer To Game',
          //   to: '/offers/volunteer-to-game',
          //   icon: 'hand'
          // },
          // {
          //   label: 'Family Gaming',
          //   to: '/offers/family-gaming',
          //   icon: 'family'
          // },
          // {
          //   label: 'IRL Esports League',
          //   to: '/offers/irl-esports-league',
          //   icon: 'esports'
          // },
          // {
          //   label: 'Party Bookings',
          //   to: '/events',
          //   category: 'party-bookings',
          //   icon: 'celebration'
          // },
          {
            label: 'Holiday Clubs',
            to: '/events',
            category: 'holiday-clubs',
            icon: 'club'
          },
          // {
          //   label: 'Industry Talks',
          //   to: '/events',
          //   category: 'industry-talks',
          //   icon: 'mic'
          // },
          {
            label: 'Minecraft',
            to: '/events',
            category: 'minecraft',
            icon: 'minecraft'
          },
          {
            label: 'Video Games Design',
            to: '/events',
            category: 'video-games-design',
            icon: 'video-game'
          }
        ]
      },
      {
        label: 'About',
        to: '/about'
      },
      {
        label: 'Contact',
        to: '/contact'
      }
    ],
    userNav: [
      {
        label: 'Admin',
        to: '/admin',
        icon: 'admin',
        isAdmin: true,
        isAuthed: true
      },
      {
        label: 'Account',
        to: '/log-in',
        icon: 'user',
        isAuthed: false
      },
      // {
      //   label: 'Dashboard',
      //   to: '/dashboard',
      //   icon: 'dashboard'
      // },
      {
        label: 'My bookings',
        to: '/dashboard/bookings',
        icon: 'bookings',
        isAuthed: true
      },
      {
        label: 'Settings',
        to: '/dashboard/settings',
        icon: 'settings',
        isAuthed: true
      },
      {
        label: 'Log Out',
        to: '/log-out',
        icon: 'logout',
        isAuthed: true
      }
    ],
    footerNav: [
      {
        label: 'Terms and Conditions',
        to: '/terms-and-conditions'
      },
      {
        label: 'Privacy Policy',
        to: '/cookie-policy'
      }
    ],
    adminNav: [
      {
        label: 'Bookings',
        to: '/admin/bookings',
        icon: 'manage-bookings'
      },
      {
        label: 'Events',
        to: '/admin/events',
        icon: 'event'
      },
      {
        label: 'Users',
        to: '/admin/users',
        icon: 'club'
      },
      {
        label: 'Settings',
        to: '/admin/settings',
        icon: 'settings'
      }
    ]
  }
}

// const createConfig = () => {
//   const env = process.env.NODE_ENV
//   return { ...config.defaults, ...config[env] }
// }
export default config
