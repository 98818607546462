import { persist } from 'zustand/middleware'
import createContext from 'zustand/context'
import { useLayoutEffect } from 'react'
import create from 'zustand'
import config from '../config'
import {
  findMatchingSlotDayIndex,
  findOverlappingSlotIndex,
  normalizeInterval
} from './lib/time-slot-fns'

// Zustand store
// Example: https://github.com/vercel/next.js/blob/canary/examples/with-zustand/lib/store.js
// More info: https://github.com/pmndrs/zustand/issues/182

// Set up context
const zustandContext = createContext()
export const Provider = zustandContext.Provider
export const useStore = zustandContext.useStore

// Config
const { bookingTypes, eventTypes } = config
let store

// Generate bookings from config

const getBookingInitialState = () => {
  const bookingsInitialState = {}
  bookingTypes.forEach((bookingType) => {
    bookingsInitialState[bookingType.type] = {
      selectedTimeSlots: []
    }
  })
  return bookingsInitialState
}

const getSelectedEventInitialState = () => {
  const selectedEventInitialState = {}
  eventTypes.forEach((type) => {
    selectedEventInitialState[type.type] = {}
  })

  return selectedEventInitialState
}

export const getDefaultInitialState = () => ({
  selectedEvent: getSelectedEventInitialState(),
  bookings: getBookingInitialState(),
  currentBooking: {
    bookingResult: null,
    itemData: null,
    people: [],
    bookingType: bookingTypes?.[0]?.type,
    bookingCity: ''
  },
  toastDetails: {
    open: false,
    message: null,
    status: 'success'
  }
})

function initializeStore(preloadedState = {}) {
  return create(
    persist(
      (set, get) => ({
        ...getDefaultInitialState(),
        ...preloadedState,
        // Handle booking slot selection
        // Different cases for bookings that allow multiple slots or multiple people
        selectBookingSlot: (
          slot,
          bookingType,
          allowMultipleDays,
          allowMultipleSlots
        ) => {
          const currentTimeSlots = get().getSelectedTimeSlots(bookingType)
          const newItemIndex = findOverlappingSlotIndex(currentTimeSlots, slot)
          let newTimeSlots = [...currentTimeSlots]

          if (allowMultipleDays && !allowMultipleSlots) {
            const sameDayBookingIndex = findMatchingSlotDayIndex(
              currentTimeSlots,
              slot
            )
            // If there are no bookings on the same day, append the slot
            if (sameDayBookingIndex === -1) {
              newTimeSlots.push(slot)
              // If there are already bookings on the same day, replace the slot
            } else {
              newTimeSlots.splice(sameDayBookingIndex, 1)
              newTimeSlots.push(slot)
            }
            // If selected the same slot, deselect it
            if (newItemIndex > -1) {
              newTimeSlots.splice(newItemIndex, 1)
            }
          } else if (!allowMultipleDays && allowMultipleSlots) {
            // If slot is not selected
            if (newItemIndex === -1) {
              const sameDayBookingIndex = findMatchingSlotDayIndex(
                currentTimeSlots,
                slot
              )
              // If there are no bookings on the same day
              if (sameDayBookingIndex === -1) {
                // But there are other days selected, replace the slots with the new one
                if (currentTimeSlots.length > 0) {
                  newTimeSlots = [slot]
                } else {
                  newTimeSlots.push(slot)
                }
                // If there are bookings on the same day, just add the slot
              } else {
                newTimeSlots.push(slot)
              }
              // if slot is selected, replace it
            } else {
              newTimeSlots.splice(newItemIndex, 1)
            }
          } else if (allowMultipleSlots && allowMultipleDays) {
            if (newItemIndex > -1) {
              newTimeSlots = [...currentTimeSlots]
              newTimeSlots.splice(newItemIndex, 1)
            } else {
              newTimeSlots = [...currentTimeSlots, slot]
            }
          } else {
            newTimeSlots = [slot]
          }

          // Update the bookings
          const updatedBookings = get().bookings
          updatedBookings[bookingType].selectedTimeSlots = newTimeSlots
          set({
            bookings: updatedBookings
          })
        },
        // Need to normalize the intervals because they get messed up when
        // saving to the local storage (when converting to json)
        getSelectedTimeSlots: (bookingType) => {
          const booking = get().bookings[bookingType]
          return booking?.selectedTimeSlots.map((slot) => ({
            ...slot,
            interval: normalizeInterval(slot.interval)
          }))
        },
        // Delete all selected booking slots for specified booking type
        clearTimeSlots: (bookingType) => {
          const updatedBookings = get().bookings
          updatedBookings[bookingType].selectedTimeSlots =
            getBookingInitialState()[bookingType].selectedTimeSlots
          set({
            bookings: updatedBookings
          })
        },
        // Delete all booking data
        deleteBookingData: () => {
          set({
            bookings: getBookingInitialState(),
            selectedEvent: getSelectedEventInitialState(),
            currentBooking: {
              people: []
            },
            bookingStep: 2
          })
        },
        // Get a selected event
        getSelectedEvent: (eventType) => {
          return get().selectedEvent[eventType]
        },
        // Set a selected event
        toggleEvent: (event) => {
          const currentEvent = get().selectedEvent[event.type]
          if (currentEvent && event._id === currentEvent._id) {
            set({
              selectedEvent: {
                ...get().selectedEvent,
                [event.type]: {}
              }
            })
          } else {
            set({
              selectedEvent: {
                ...get().selectedEvent,
                [event.type]: event
              }
            })
          }
        },
        setBookingStep: (step) => {
          set({
            bookingStep: step
          })
        },
        // All information about current booking
        // bookingResult - response from server
        // bookingType - type of booking
        // itemData - data for booking
        setCurrentBooking: ({
          bookingResult,
          bookingData,
          itemData,
          bookingType,
          bookingCity,
          people
        }) => {
          set({
            currentBooking: {
              ...get().currentBooking,
              ...(bookingResult && { bookingResult }),
              ...(bookingData && { bookingData }),
              ...(itemData && { itemData }),
              ...(bookingType && { bookingType }),
              ...(bookingCity && { bookingCity }),
              ...(people && { people })
            }
          })
        }
      }),
      {
        name: 'global-app-3', // Update when store structure changes changes in store
        getStorage: () => sessionStorage
      }
    )
  )
}

export function useCreateStore(serverInitialState) {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initializeStore(serverInitialState)
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(store)
  store = store ?? initializeStore(serverInitialState)
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (serverInitialState && isReusingStore) {
      store.setState(
        {
          // re-use functions from existing store
          ...store.getState(),
          // but reset all other properties.
          ...serverInitialState
        },
        true // replace states, rather than shallow merging
      )
    }
  })

  return () => store
}
